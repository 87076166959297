:root {
  --gradient-bg1: 255, 255, 255;
  --gradient-bg2: 11, 19, 43;

  --header-bg-color: rgb(11, 19, 43);

  --bs-focus-ring-color: rgba(255, 255, 255, 0.25);
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Source Code Pro", monospace;
}

.carousel-caption {
  /* background-color: red; */
  /* box-shadow: inset 0 -10px 10px rgba(0, 0, 0, 1); */
  /* border-radius: 5px; */
  /* top: 0px; */
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-bottom: 2.5rem;
  padding-top: 6rem;
  /* height: 100%; */
  /* background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.8)
  ); */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* .carousel:focus .carousel-caption {
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.8)
  );
} */

.svg-button {
  transition: transform 0.1s ease-in-out;
}

.svg-button:hover {
  transform: scale(1.1);
}

.svg-button:active {
  transform: scale(0.9);
}

.about-card-header {
  /* background-color: var(--bs-gray-800); */
  background-color: var(--header-bg-color);
}

.round-bg {
  width: 110px;
  height: 110px;
  transform: translateX(-10px) scale(1.3);
  background: radial-gradient(
    circle,
    rgba(var(--gradient-bg1), 1),
    rgba(var(--gradient-bg2), 1)
  );
  border-radius: 50%;
  /* z-index: -1; */
}

.round-bg-img {
  width: 110px;
  height: 120px;
  /* transform: translateY(-20%); */
  transform: translateX(-10px) scale(1.3);
  /* background: radial-gradient(circle, rgb(67, 242, 58), rgba(252, 70, 107, 1)); */

  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  border-radius: 0 0 50% 50%;
  overflow: hidden;
}

.round-bg-img img {
  transform: translate(3px, -12px) scale(0.8);
  animation: scaleUp 2s ease-in-out;
  transition: transform 0.3s ease-in-out;
}

.round-bg-img img:hover {
  transform: translate(3px, -15px) scale(0.9);
  transition: transform 0.3s ease-in-out;
}

.contact-slip a {
  font-size: 0.875rem;
}

.contact-slip:hover {
  /* transform: scale(1.05); */
  transform: translate(1.5%, -5%) scale(1.03);
}

@keyframes scaleUp {
  from {
    transform: translate(3px, -15px) scale(0.9);
  }
  to {
    transform: translate(3px, -12px) scale(0.8);
  }
}

#footer-nav {
  position: relative;
}

#footer-nav .navbar-nav {
  gap: 3rem;
  padding-top: 1.5rem;
}

#footer-nav svg {
  font-size: calc(3rem + 0.6vw);
}

@media (min-width: 576px) {
  #footer-nav {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }

  #footer-nav .navbar-nav {
    gap: 3rem;
    padding-top: 0rem;
  }

  #footer-nav svg {
    font-size: calc(1.3rem + 0.6vw);
  }
}
